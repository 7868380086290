function moveInterval(iconos, textos, tiempoI, tiempoT) {

    const conteIconos = document.querySelectorAll(iconos);
    const textosL = document.querySelectorAll(textos);

    let timeDelay = 0;
    let timeDelay2 = 0;

    conteIconos.forEach(element => {
        element.style.animation = `appear ${tiempoI}s ease ${timeDelay}s 1 normal forwards`;
        timeDelay += 0.4;
    });

    textosL.forEach(element => {
        element.style.animation = `appear ${tiempoT}s ease ${timeDelay2}s 1 normal forwards`;

        timeDelay2 += 0.4;
    });
}

window.addEventListener('scroll', function() {
    const y = window.scrollY;
    const anclaEval = document.querySelector('.ancla-evaluacion');
    const tituloEval = document.querySelector('.titulo-Eval');
    const tituloEval2 = document.querySelector('.UniEval .parte1 h2');
    const iconos = document.querySelectorAll('.conte_iconos .iconos');
    const imgEval = document.querySelector('.UniEval .parte2 .imgevaluacion');
    const buttonEval = document.querySelector('.UniEval .parte2 button');
    const mensajeEval = document.querySelector('.UniEval .parte2 .mensaje');
    const mailEval = document.querySelector('.UniEval .parte3 .mail');
    const anchoPantalla = document.body.clientWidth;    

    if(anchoPantalla > 1418) {
        if(y >= 4000 && y <= 4500) {
            animationEval(anclaEval, tituloEval, tituloEval2, imgEval, buttonEval, mensajeEval, mailEval, iconos, 0, 0, 2);
         } 
    } else if(anchoPantalla > 800 && anchoPantalla < 1419){
        if(y >= 4200 && y <= 4700) {
            animationEval(anclaEval, tituloEval, tituloEval2, imgEval, buttonEval, mensajeEval, mailEval, iconos, 0, 0, 2);
        }
    }else{
        if(y >= 5042 && y <= 6342) {
            animationEval(anclaEval, tituloEval, tituloEval2, imgEval, buttonEval, mensajeEval, mailEval, iconos, 3, 3, 4);
        }
    }
  });


function animationEval(anclaEval, tituloEval, tituloEval2, imgEval, buttonEval, mensajeEval, mailEval, iconos, delayimg, delaybutton, delaymensaje) {

    if(anclaEval) {
        anclaEval.style.animation = `appear 1s ease 0s 1 normal forwards`;
    }

    tituloEval.style.animation = 'fadeSide1 2s ease 0s 1 normal forwards';
    tituloEval2.style.animation = 'fadeSide1 2s ease .8s 1 normal forwards';
    imgEval.style.animation = `fadeSide1 1.5s ease ${delayimg}s 1 normal forwards`;
    buttonEval.style.animation = `appear 3s ease ${delaybutton}s 1 normal forwards`;
    mensajeEval.style.animation = `fadeSide1 2s ease ${delaymensaje}s 1 normal forwards`;
    moveInterval('.conte_iconos', '.grid_it .textos', 4, 4);
    
    if(mailEval) {
        mailEval.style.animation = `fadeSide1 2s ease ${delaymensaje}s 1 normal forwards`;
    }

    setTimeout(() => {
            iconos.forEach(icono => {
                icono.style.setProperty('--opa', '1');
            });
        }, 3500);
}



